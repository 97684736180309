import React from "react";

function Header() {
  return (
    <header>
        <h1 id="title">Statistics Calculator</h1>
    </header>
  );
}

export default Header;